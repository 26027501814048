<template>
  <div>
    <div class="main">
      <div class="title">
        <i @click="goBack" class="iconfont icon-return"></i>
        <span> 案件詳細 </span>
      </div>
      <div class="content-box">
        <div class="setting">
          <div class="left">
            <img :src="caseInfo.icon" alt="" />
          </div>
          <div class="right">
            <h3> {{ caseInfo.name }}</h3>
            <p>
              <span>校了締め日：{{ date }}</span>
              <span></span>
            </p>
            <div>
              <span v-if="isRole" @click="modify">案件情報修正</span>
              <span v-if="isRole" @click="go_process">作業フロー設定</span>
            </div>
          </div>
        </div>
        <div class="content">
          <ul>
            <li
              v-for="item in pageList"
              :key="item.num"
              v-show="item.pageuser.length"
              :class="{
                begin: item.status === 1,
                await: item.status === 3,
                success: item.status === 4,
                back: item.status === 5,
                pink: item.status === 2
              }"
              @click="handleClick(item)"
            >
            <template>
              <div class="content-info">
                <div class="page-progress">
                  <p>NO {{ item.num }} 頁</p>
                  <!-- <p>完成：{{ item.process[0] * 1 - 1 }} / 総：{{ item.process[1] * 1 - 1 }}</p> -->
                </div>
                <p>
                  <!-- 进度 -->
                  ステータス：
                  <span v-if="item.status === 1">作業開始前</span>
                  <span v-if="item.status === 2">作業中</span>
                  <span v-if="item.status === 3">承認待ち</span>
                  <span v-if="item.status === 4">完成</span>
                  <span v-if="item.status === 5">否決</span>
                </p>
                <p>
                  担当者：{{ item.nickname }}
                </p>
                <p>更新日時：{{ item.update_at }}</p>
                <!-- <span @click="handleClick(item)">確認</span> -->
              </div>
              </template>
              <div class="progress-box" v-if="item.fenshu!='NaN'">
                <div class="pro">
                  <el-progress :text-inside="true" :stroke-width="15" :percentage="item.fenshu * 1"></el-progress>
                </div>
                <div>{{item.process[0] * 1 - 1 }}/{{  item.process[1] * 1 - 1 }}</div>
              </div>
              <div class="progress-box" v-else>
                <div class="pro">
                  <el-progress :text-inside="true" :stroke-width="15" :percentage="0"></el-progress>
                </div>
                <div>0/0</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="downFile">
          <span class="downBtn" @click="downLoadFile">原稿ダウンロード<br>（閲覧用）</span>
          <span class="jiaoyan" @click="checkPass" v-if="loginAttr!= 1 && caseInfo.status != 3">校了完了</span>
        </div>
      </div>
    </div>

    <el-dialog
      :title="`第${num}頁`"
      :visible.sync="dialogTableVisible"
      width="70%"
    >
      <el-table :data="pageDetail">
        <el-table-column prop="icon" label="サムネイル画像">
          <template scope="scope">
            <div class="img_">
              <img :src="scope.row.icon" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="rownum" label="作業順番"> </el-table-column>
        <el-table-column prop="position" label="タスク"></el-table-column>
        <el-table-column property="nickname" label="担当者"></el-table-column>
        <el-table-column property="status" label="ステータス">
          <template scope="scope">
            <span v-if="scope.row.status == 1">未着手</span>
            <span v-if="scope.row.status == 2">承認待ち</span>
            <span v-if="scope.row.status == 3">完成</span>
            <span v-if="scope.row.status == 4">否決</span>
            <span v-if="scope.row.status == 5">作業中</span>
          </template>
        </el-table-column>
        <el-table-column
          property="update_at"
          label="最終更新時間"
          width="250"
        ></el-table-column>
        <el-table-column width="100px" property="status" label="操作">
          <template slot-scope="scope">
            <el-dropdown trigger="click" v-if="!scope.row.isview">
              <div style="cursor:pointer;color: #409EFF">
                クリック<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :disabled="scope.row.status == 1 || scope.row.status == 3" v-if="scope.row.status != 3" @click.native="checkIn(scope.row)"
                  ><span 
                    >チェックイン</span
                  ></el-dropdown-item
                >
                <el-dropdown-item :disabled="scope.row.status == 1" @click.native="fileDownLoad(scope.row)"
                  ><span>ダウンロード</span
                  ></el-dropdown-item
                >
                <el-dropdown-item :disabled="scope.row.status == 1" v-if="scope.row.status != 3" @click.native="updateHistory(scope.row)"
                  ><span 
                    >更新履歴</span
                  ></el-dropdown-item
                >
                <el-dropdown-item :disabled="scope.row.status == 1" v-if="scope.row.status != 3" @click.native="getBackNote(scope.row)"
                  ><span 
                    >コメント</span
                  ></el-dropdown-item
                > 
                <el-dropdown-item  v-if="scope.row.status == 2 && isCheck"  @click.native="pass(scope.row)"
                  ><span>承認</span></el-dropdown-item
                >
                <el-dropdown-item v-if="scope.row.status == 2 && isCheck" @click.native="goBack_(scope.row)"
                  ><span 
                    >否決</span
                  ></el-dropdown-item
                >
              </el-dropdown-menu>
             
            </el-dropdown>
            
            <span v-if="scope.row.isview" style="cursor:pointer; color:#1875d2" @click="fileDownLoad(scope.row)">作業開始</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog class="upLoadIcon" title="ファイルアップロード" :visible.sync="imgdialog" width="50%">
      <el-upload
        class="avatar-uploader"
        action="no"
        accept=".jpg, .jpeg, .png, .clip"
        :http-request="upLoadIcon"
        :show-file-list="false"
      >
      <!-- <img v-if="iconUrl" :src="iconUrl"
                    style="width: 100%" /> -->
      <i class="el-icon-camera avatar-uploader-icon"></i>
    </el-upload>
    </el-dialog>
    <el-dialog :visible.sync="historyDialog" width="50%">
      <div slot="title" v-if="isStatus == 1">更新履歴</div>
      <div slot="title" v-if="isStatus == 2">コメント</div>
      <div slot="title"></div>
      <div class="history-list" v-if="isStatus == 1">
        <ul>
          <li v-for="(item, index) in historyArr" :key="index">
            <span>{{ item.icon }}</span>
            <p>{{ item.create_at }}</p>
            <span @click="historyDownLoad(item)">ダウンロード</span>
          </li>
        </ul>
      </div>
      <div v-if="isStatus == 2" class="history-list">
        <ul>
          <li v-for="(item, index) in historyArr" :key="index">
            <span>{{ item.note }}</span>
            <p>{{ item.create_at }}</p>
          </li>
        </ul>
      </div>
    </el-dialog>
    <el-dialog title="否決コメント" :visible.sync="backDialog" width="50%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="コメント">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="backDialog = false">取　消</el-button>
        <el-button type="primary" @click="confrimBack()">確　定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/service";
import utils from '@/assets/js/utils'
export default {
  data() {
    return {
      caseInfo: {},
      pageList: [],
      dialogTableVisible: false,
      historyDialog: false,
      imgdialog: false,
      backDialog: false,
      pageDetail: [],
      pageUserInfo: {},
      iconUrl:"",
      historyArr: [],
      isCheck: false,
      num: '',
      form: {
        desc: "",
      },
      backData: {},
      isStatus: 1,
      role: [],
      loginAttr: '',
      active:0,
    };
  },
  mounted() {
    const loginAttr = JSON.parse(window.localStorage.getItem('userInfo')).data.loginattr 
    this.loginAttr = loginAttr
    this.caseInfo = JSON.parse(this.$route.query.params);
    
    this.role = utils.getStorage("userInfo").data.role.split(",")
    
    this.getPageList({
      projectid: this.caseInfo.projectid,
      chapterid: this.caseInfo.id,
    });
  },
  computed: {
    date() {
      if (this.caseInfo.check_at) {
        return this.caseInfo.check_at.slice(0, 10);
      } else {
        return "";
      }
    },
    isRole() {
      if(this.role.indexOf("1") > -1) {
        return true
      }
      return false
    },
  },
  methods: {
    confrimBack() {
      let params = {
        projectid: this.backData.projectid,
        chapterid: this.backData.chapterid,
        pageid: this.backData.pageid,
        douserid: this.backData.userid,
        pageuserid: this.backData.id,
        content: this.form.desc,
        posid: this.backData.posid,
        icon: this.backData.icon,
      };
      if (this.form.desc) {
        service.personal.backPage(params).then((res) => {
          if (res.status === 200) {
            this.$message.success("否決");
            this.backDialog = false;
			      this.dialogTableVisible = false;
            this.getPageList({
              projectid: this.caseInfo.projectid,
              chapterid: this.caseInfo.id,
            });
          }
        });
      } else {
        this.$message.error("コメントを入力して下さい");
      }
    },
    downLoadFile() {
      window.location.href = `/chapter/downall?chapterid=${this.caseInfo.id}`;
    },
    //校验通过
    checkPass() {
      const params = {
        chapterid: this.caseInfo.id
      }
      return service.personal.chapterCheckpage(params).then(res => {
        if(res.status === 200) {
          this.$message.success('校了完了')
        }
      }) 
    },
    goBack_(data) {
      this.backDialog = true;
      this.backData = data;
    },
    pass(data) {
      let params = {
        pageuserid: data.id,
        pageid: data.pageid,
        chapterid: data.chapterid,
      };
      service.personal
        .passPage(params)
        .then((res) => {
          if (res.status === 200) {
            this.$message.success("承認する");
            this.getPageList({
              projectid: this.caseInfo.projectid,
              chapterid: this.caseInfo.id,
            });
            this.dialogTableVisible = false;
          }
        })
        .catch((err) => {});
    },
    historyDownLoad(data) {
      let fileName = data.icon;
      let index = fileName.lastIndexOf("\/");
      fileName = fileName.substring(index + 1, fileName.length);
      window.location.href = `/chapter/downloadpage?projectid=${data.projectid}&chapterid=${data.chapterid}&pageid=${data.pageid}&filename=${fileName}`;
    },
    updateHistory(data) {
      this.isStatus = 1;
      this.historyDialog = true;
      let params = {
        projectid: data.projectid,
        chapterid: data.chapterid,
        pageid: data.pageid,
        douserid: data.userid,
        type: this.isStatus,
        pageuserid: data.id,
      };
      service.personal
        .getHistory(params)
        .then((res) => {
          if (res.status === 200) {
            this.historyArr = res.data;
          }
        })
        .catch((err) => {});
    },
    getBackNote(data) {
      this.isStatus = 2;
      this.historyDialog = true;
      let params = {
        projectid: data.projectid,
        chapterid: data.chapterid,
        pageid: data.pageid,
        douserid: data.userid,
        pageuserid: data.id,
        type: 2,
      };
      service.personal
        .getHistory(params)
        .then((res) => {
          if (res.status === 200) {
            this.historyArr = res.data;
          }
        })
        .catch((err) => {});
    },
    fileDownLoad(data) {
      console.log(data,"999")
      let fileName = data.icon;
      let index = fileName.lastIndexOf("\/");
      fileName = fileName.substring(index + 1, fileName.length);
     window.location.href = `/chapter/downloadpage?projectid=${data.projectid}&chapterid=${data.chapterid}&pageid=${data.pageid}&filename=${fileName}&userid=${this.pageDetail[data.rownum].userid}&pageuserid=${this.pageDetail[data.rownum].id}`;
     this.dialogTableVisible = false 
      this.getPageList({
      projectid: this.caseInfo.projectid,
      chapterid: this.caseInfo.id,
    });
    },
    checkIn(data) {
      let params = {
        projectid: data.projectid,
        chapterid: data.chapterid,
        pageid: data.pageid,
        pageuserid: data.id,
        posid: data.posid,
        douserid: data.userid,
      };
      this.pageUserInfo = params;

      if (data.istimeup && data.isup) {
        this.imgdialog = true;
        this.dialogTableVisible = false;
      } else {
        if (!data.istimeup) {
          this.$message.warning("前作業完了待ち");
          return
        }
        if (!data.isup) {
          this.$message.warning("権限なし");
        }
      }
    },
    upLoadIcon(e) {
      if (e.file) {
        const file = e.file;
        let formData = new FormData();
        formData.append("file", file);
        formData.append("projectid", this.pageUserInfo.projectid);
        formData.append("chapterid", this.pageUserInfo.chapterid);
        formData.append("pageid", this.pageUserInfo.pageid);
        formData.append("pageuserid", this.pageUserInfo.pageuserid);
        formData.append("posid", this.pageUserInfo.posid);
        formData.append("douserid", this.pageUserInfo.douserid);
        service.personal.upLoadPage(formData).then((res) => {
          if (res.status === 200) {
            this.$message.success("ファイルがアップロードされました");
            this.imgdialog = false;
            this.dialogTableVisible = false;
            this.iconUrl = res.data.filename;
            this.getPageList({
              projectid: this.caseInfo.projectid,
              chapterid: this.caseInfo.id,
            });
          }
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    go_process() {
      this.$router.push({
        path: "proces-setting",
        query: {
          data: JSON.stringify(this.caseInfo),
          id: 1,
        },
      });
    },
    modify() {
      if(this.caseInfo.status==3) {
        this.$message.error("この案件が完成している為、修正不可")
        return
      }
      this.$router.push({
        path: "creat-case",
        query: {
          params: JSON.stringify(this.caseInfo),
          id: 1,
        },
      });
    },
    //获取页的列表
    getPageList(data) {
      service.personal
        .getPageList(data)
        .then((res) => {
          if (res.status === 200) {
            this.pageList = res.data;
            this.pageList = this.pageList.map(item => {
              return {
                ...item,
                process: item.process ? item.progress.split('/') : [],
              }
            })
            this.pageList = this.pageList.map(item => {
              return {
                ... item,
                fenshu: ((item.process[0] - 1) / ( item.process[1] - 1 )* 100).toFixed(1) 
              }
            })
            console.log(this.pageList)
            this.isCheck = res.checkdetail;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClick(data) {
     
      this.dialogTableVisible = true;
      this.num = data.num
      this.pageDetail = data.pageuser;
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  .downFile {
    margin-top: 30px;
    span {
      background: #f03747;
       color: #fff;
       display: inline-block;
        width: 250px;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        margin: 0 10px;
    }
    .downBtn {
      padding: 10px 0;
      width: 150px;
    }
    .jiaoyan {
        height: 50px;
        line-height: 50px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    i {
      font-size: 20px;
      cursor: pointer;
    }
    span {
      color: #333;
      margin-left: 10px;
    }
  }
  .content-box {
    margin-top: 20px;
    .setting {
      display: flex;
      .left {
        width: 240px;
        height: 170px;
        min-width: 180px;
        min-height: 100px;
        position: relative;
        border-radius: 5px;
        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          border-radius: 5px;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }
      .right {
        margin-left: 30px;
        h3 {
          height: 40px;
          line-height: 40px;
          letter-spacing: 2px;
        }
        p {
          height: 35px;
          line-height: 35px;
          font-size: 12px;
          color: #999;
          span:last-child {
            margin-left: 35px;
          }
        }
        div {
          display: flex;
          margin-top: 40px;
          span {
            display: block;
            width: 150px;
            height: 50px;
            line-height: 50px;
            border-radius: 8px;
            text-align: center;
            cursor: pointer;
          }
          span:first-child {
            background: #f03747;
            color: #fff;
          }
          span:last-child {
            border: 1px solid #f03747;
            color: #f03747;
            margin-left: 10px;
          }
        }
      }
    }
    .content {
      margin-top: 15px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          margin: 10px;
          padding: 10px;
          border-radius: 4px;
          width: 22%;
          cursor: pointer;
          .content-info {
            font-size: 12px;
            padding: 10px;
            border-radius: 4px;
          .page-progress {
              display: flex;
              p:first-child {
                flex: 1;
              }
            }
            p {
              line-height: 35px;
            }
            > span {
              margin-top: 10px;
              display: block;
              height: 25px;
              width: 60px;
              text-align: center;
              line-height: 25px;
              border: 1px solid #ccc;
              border-radius: 5px;
              color: #fff;
              background-color: #ccc;
              cursor: pointer;
            }
          }
          .progress-box {
            display: flex;
            margin-top: 10px;
            .pro {
              flex: 1;
            }
          }
        }
        li.success {
          .content-info {
            background-color: rgba(0, 255, 115, 0.3);
            border: 1px solid rgba(0, 255, 115, 0.8);
            > span {
              border: 1px solid rgba(5, 221, 102, 0.952);
              background-color: rgba(5, 221, 102, 0.952);
            }
          }
        }
        li.await {
          .content-info {
            background-color: rgba(15, 52, 153, 0.3);
            border: 1px solid rgba(0, 60, 255, 0.8);
            > span {
              border: 1px solid rgba(5, 106, 221, 0.952);
              background-color: rgba(5, 106, 221, 0.952);
            }
          }
        }
        li.pink {
          .content-info {
              background-color: pink;
              border: 1px solid rgba(228, 110, 74, 0.8);
              >span {
                background-color: green;
                color: #fff;
              }
          }
        }
        li.back {
          .content-info {
            background-color: rgba(189, 79, 16, 0.3);
            border: 1px solid rgba(228, 110, 74, 0.8);
            > span {
              border: 1px solid rgba(221, 5, 70, 0.952);
              background-color: rgba(221, 5, 70, 0.952);
            }
          }
        }
      }
    }
  }
  .img_ {
    width: 100px;
    height: 30px;
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      //   margin: auto;
    }
  }
}
.upLoadIcon {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;
  img {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.style {
  padding: 0 10px;
  cursor: pointer;
  color: #1875d2;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px solid #999;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  background: #f4f4f4;
  text-align: center;
  margin: 0 auto;
}
.avatar-uploader-icon {
  font-size: 40px;
  color: #8c939d;
  line-height: 100px;
}
.history-list {
  ul {
    li {
      display: flex;
      align-items: center;
      height: 40px;
      p {
        flex: 1;
        margin-left: 40px;
      }
      span:last-child {
        color: #1875d2;
        cursor: pointer;
      }
    }
  }
}
</style>
